import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../App.css';

function RankHype() {
  const [mcs, setMcs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Função para alternar o estado do menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // useEffect para buscar o JSON da API
  useEffect(() => {
    axios.get('https://scoreboard-j6oz.onrender.com/mcs/ranking-hype') // URL correta da API
      .then(response => {
        setMcs(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Houve um erro ao buscar os dados:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Navbar */}
      <nav className="navbar navbar-dark bg-primary">
        <div className="container">
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link className="navbar-brand" to="/">
            <i className="fa d-inline fa-lg fa-circle-o"></i>
            <b>Rhymes Score</b>
          </Link>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/topbigfour">Top 5 Big Four - São Paulo SP - 2024</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/rankhype">Ranking do Hype</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">Sobre Nós</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Título da Página */}
        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="editions-title">Ranking do Hype</h2>
              </div>
            </div>
          </div>
        </div>

      {/* Lista de MCs em Cards */}
      <div className="container flex-grow-1">
        <div className="row">
          {loading ? (
            <div className="col-md-12 text-center">
              <p>Carregando...</p>
            </div>
          ) : (
            mcs.map((mc, index) => (
              <div key={index} className="col-md-12 mb-4">
                <div className="card h-100 shadow">
                  <div className="card-body d-flex align-items-center">
                    <Link to={`/profile/${mc.nickName}`}>
                      <img 
                        className="img-fluid rounded-circle me-3" 
                        src={mc.photo} 
                        width="80" 
                        alt={`Foto de ${mc.nickName}`} 
                      />
                    </Link>
                    <div className="text-left">
                      <h5 className="card-title mb-1">{index + 1}º {mc.nickName}</h5>
                      <p className="card-text mb-0">Visualizações no YouTube: {mc.totalViews.toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Rodapé */}
      <footer className="bg-primary text-white text-center py-3">
        <p className="mb-0">© 2024 Rhymes Score. Todos os direitos reservados.</p>
      </footer>
    </div>
  );
}

export default RankHype;
