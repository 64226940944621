import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../App.css'; // Certifique-se de que o caminho está correto

function AboutUs() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App">
      <nav className="navbar navbar-dark bg-primary">
        <div className="container">
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="#">
            <b>Rhymes Score</b>
          </a>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/topbigfour">Top 5 Big Four - São Paulo SP - 2024</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/rankhype">Ranking do Hype</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">Sobre Nós</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Conteúdo principal */}
      <div className="main-content">
        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="editions-title">Sobre Nós</h1>
                <p className="text-body">
                  O serviço Rhymes Score apresenta resultados atualizados das Batalhas de Rima de todo o Brasil. 
                  Acompanhe seus Mcs favoritos aqui!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Rodapé cobrindo toda a largura da tela */}
      <footer>
        <div className="text-center">
          <p className="mb-0">© 2024 Rhymes Score. All rights reserved</p>
        </div>
      </footer>
    </div>
  );
}

export default AboutUs;
