import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import '../App.css';

function Edition() {
  const { organization, number } = useParams();
  const [battleData, setBattleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const fetchBattleData = async () => {
      try {
        const response = await axios.get(`https://scoreboard-j6oz.onrender.com/editions/organization/${organization}/number/${number}/detail`);
        setBattleData(response.data.battle);
      } catch (error) {
        console.error('Error fetching battle data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBattleData();
  }, [organization, number]);

  const toggleCollapse = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (loading) {
    return (
      <div className="text-center py-3">
        <ClipLoader color="#000" loading={loading} size={35} />
      </div>
    );
  }

  if (!battleData || battleData.length === 0) {
    return <p>Nenhuma batalha encontrada.</p>;
  }

  // Agrupando batalhas por fase e lado
  const groupedBattles = battleData.reduce((acc, battle) => {
    const key = battle.phase === battle.side ? battle.phase : `${battle.phase} - ${battle.side}`;
    
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(battle);
    return acc;
  }, {});

  // Ordem desejada das fases
  const phaseOrder = ['FINAL', 'SEMI_FINAL', 'PHASE_2', 'PHASE_1'];

  // Função para renomear as fases com nomes personalizados
  const phaseNameMap = (phase) => {
    let newPhase = phase;

    if (phase.startsWith('PHASE_2')) {
      newPhase = phase.replace('PHASE_2', 'SEGUNDA FASE');
    } else if (phase.startsWith('PHASE_1')) {
      newPhase = phase.replace('PHASE_1', 'PRIMEIRA FASE');
    }

    // Renomeia "GROUP_A" para "LADO A" e "GROUP_B" para "LADO B"
    if (newPhase.includes('GROUP_A')) {
      newPhase = newPhase.replace('GROUP_A', 'LADO A');
    }
    if (newPhase.includes('GROUP_B')) {
      newPhase = newPhase.replace('GROUP_B', 'LADO B');
    }

    return newPhase;
  };

  // Ordenando as fases de acordo com a ordem desejada
  const orderedGroups = Object.keys(groupedBattles).sort((a, b) => {
    const phaseA = phaseOrder.indexOf(a.split(' ')[0]);
    const phaseB = phaseOrder.indexOf(b.split(' ')[0]);
    return phaseA - phaseB;
  });

  return (
    <div className="App"> {/* Certifique-se de usar a classe App ou o que você tiver configurado */}
      <nav className="navbar navbar-dark bg-primary">
        <div className="container">
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="#">
            <i className="fa d-inline fa-lg fa-circle-o"></i>
            <b>Rhymes Score</b>
          </a>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/topbigfour">Top 5 Big Four - São Paulo SP - 2024</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/rankhype">Ranking do Hype</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">Sobre Nós</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="main-content py-5"> {/* Adicionei a classe main-content */}
        <div className="container">
          <h2 className="editions-title">Batalhas</h2> 
          <div id="accordion">
            {orderedGroups.map((group, index) => (
              <div className="card mb-3 border-primary shadow" key={index}>
                <div className="card-header bg-primary text-white" id={`heading-${index}`}>
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link text-white"
                      onClick={() => toggleCollapse(index)}
                      aria-expanded={activeIndex === index}
                      aria-controls={`collapse-${index}`}
                    >
                      {phaseNameMap(group)}
                    </button>
                  </h5>
                </div>

                <div
                  id={`collapse-${index}`}
                  className={`collapse ${activeIndex === index ? 'show' : ''}`}
                  aria-labelledby={`heading-${index}`}
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    {groupedBattles[group].map((battle, battleIndex) => (
                      <div className="card mb-3" key={battleIndex}>
                        <div className="card-body">
                          <div className="d-flex justify-content-center align-items-center mb-2">
                            <div className="text-center">
                              {/* Exibindo todos os MCs do vencedor */}
                              {battle.winner.mcs.map((mc, mcIndex) => (
                                <img 
                                  key={mcIndex} 
                                  src={mc.photo} 
                                  alt={mc.nickName} 
                                  className="battle-img mx-1" 
                                />
                              ))}
                            </div>
                            <h5 className="mb-0 mx-2">
                              {battle.winnerScore} - {battle.competitorScore}
                            </h5>
                            <div className="text-center">
                              {/* Exibindo todos os MCs do competidor */}
                              {battle.competitor.mcs.map((mc, mcIndex) => (
                                <img 
                                  key={mcIndex} 
                                  src={mc.photo} 
                                  alt={mc.nickName} 
                                  className="battle-img mx-1" 
                                />
                              ))}
                            </div>
                          </div>

                          <div className="d-flex justify-content-center align-items-center">
                            <div className="text-center mx-2">
                              {battle.winner.mcs.map((mc, mcIndex) => (
                                <span key={mcIndex}>{mc.nickName} </span>
                              ))}
                            </div>
                            <a 
                              href={battle.linkVideo} 
                              target="_blank" 
                              rel="noopener noreferrer" 
                              className="mx-2"
                            >
                              <i className="fab fa-youtube fa-2x"></i>
                            </a>
                            <div className="text-center mx-2">
                              {battle.competitor.mcs.map((mc, mcIndex) => (
                                <span key={mcIndex}>{mc.nickName} </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <footer className="bg-primary text-white text-center py-3">
        <p className="mb-0">© 2024 Rhymes Score. Todos os direitos reservados</p>
      </footer>
    </div>
  );
}

export default Edition;
