import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import TopBigFour from './components/TopBigFour';
import Profile from './components/Profile';
import Edition from './components/Edition';
import RankHype from './components/RankHype';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/topbigfour" element={<TopBigFour />} />
        <Route path="/profile/:nickName" element={<Profile />} /> 
        <Route path="/edition/organization/:organization/number/:number" element={<Edition />} />
        <Route path="/rankhype" element={<RankHype />} />
      </Routes>
    </Router>
  );
}

export default App;
