import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactEcharts from 'echarts-for-react';
import { ClipLoader } from 'react-spinners';
import '../App.css'; // Certifique-se de que o caminho do CSS esteja correto

function ProfileChart({ nickName }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Adicionado para gerenciar erros

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://scoreboard-j6oz.onrender.com/mcs/performance/${nickName}`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Não foi possível carregar os dados. Tente novamente mais tarde.');
      } finally {
        setLoading(false); // Define o estado de carregamento como false independentemente do resultado
      }
    };

    fetchData();
  }, [nickName]); // Atualiza os dados sempre que o nickName mudar

  const generateChartOptions = () => {
    const seriesData = data.map(entry => ({ value: entry.score, name: entry.organization }));

    return {
      title: {
        text: `Desempenho de ${nickName}`,
        subtext: 'Big Four São Paulo - SP - 2024',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Vitórias',
          type: 'pie',
          radius: '50%',
          data: seriesData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
  };

  return (
    <div className="d-flex flex-column min-vh-100"> {/* Estrutura flexível */}
      <div className="container py-5 flex-grow-1"> {/* Container para o gráfico */}
        {loading ? (
          <div className="text-center py-3">
            <ClipLoader color="#000" loading={loading} size={35} />
          </div>
        ) : error ? (
          <div className="alert alert-danger text-center">{error}</div> // Exibe mensagem de erro
        ) : (
          <ReactEcharts option={generateChartOptions()} />
        )}
      </div>

      {/* Rodapé, aparece apenas uma vez */}
      <footer className="bg-primary text-white text-center py-3">
        <p className="mb-0">© 2024 Rhymes Score. Todos os direitos reservados.</p>
      </footer>
    </div>
  );
}

export default ProfileChart;
