import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactEcharts from 'echarts-for-react';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

function Chart() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://scoreboard-j6oz.onrender.com/mcs/top');
        // Inverte a ordem dos dados para que o MC com o maior score apareça no topo
        const reversedData = response.data.reverse();
        setData(reversedData);
        setLoading(false); // Define o estado de carregamento como false quando os dados são recebidos
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Em caso de erro, também definimos o estado de carregamento como false
      }
    };

    fetchData();
  }, []);

  const option = {
    dataset: {
      source: [['score', 'nickName'], ...data.map(item => [item.score, item.nickName])]
    },
    grid: { containLabel: true },
    xAxis: { name: 'score' },
    yAxis: { type: 'category' },
    visualMap: {
      orient: 'horizontal',
      left: 'center',
      min: 1,
      max: 10,
      text: ['High Score', 'Low Score'],
      dimension: 0,
      inRange: {
        color: ['#65B581', '#FFCE34', '#FD665F']
      }
    },
    series: [
      {
        type: 'bar',
        encode: {
          x: 'score',
          y: 'nickName'
        }
      }
    ]
  };

  return (
    <div>
      {loading ? (
        <div className="text-center py-3">
          <ClipLoader color="#000" loading={loading} size={35} />
        </div>
      ) : (
        <ReactEcharts
          option={option}
          opts={{ renderer: 'svg' }}
          notMerge={true}
          lazyUpdate={true}
          style={{ height: '500px', width: '100%' }}
          className='react_for_echarts'
          onEvents={{
            click: (params) => {
              if (params.componentType === 'series') {
                const nickName = params.value[1];
                window.location.href = `/profile/${nickName}`;
              }
            }
          }}
        />
      )}
    </div>
  );
}

export default Chart;
